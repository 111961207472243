/**
 * Contains pre-set custom charts called "Summary Charts" on the Custom Charts page.
 */

/**
 * Chart Attributes
 *
 * displayName:   {string} Name of the chart
 * rowGroup:      {string} Field key for the row (Pie Charts only need a rowGroup no columnGroup)
 * rowSubGroup:   {string} (Optional) field key for 'Table' charts row sub group
 * columnGroup:   {string} Field key for the column
 * mode:          {string} Type of chart (Bar Chart, Stacked Bar Chart, Pie Chart, or Table)
 * showInGrades:  {array} An array of numbers to specify which grades to include the chart in
 * filters:       {array} An array of filter objects
 *
 * Filter Object Attributes (for each filter object in the filters array)
 *
 * displayName:   {string} Name of the filter
 * displayType:   {string} Display name for the type of filter
 * field:         {string} Field key that is being filtered
 * type:          {string} Type of filter
 * value:         {string, array} The value to be filtered by
 */
export const setCharts = (currentQuarter) => {
  return [
    { displayName: "", showInGrades: [8, 9, 10, 11, 12] },
    {
      displayName: "Sample: Passing Courses by Avg. Attendance",
      rowGroup: "ADA",
      columnGroup: "passingCourseCount",
      mode: "Stacked Bar Chart",
      showInGrades: [10, 11, 12],
      filters: [],
    },
    {
      displayName: "Current Quarter On-Track",
      rowGroup: "currentGradeLevel",
      columnGroup: "_currentQuarterOnTrackStatus",
      mode: "Bar Chart",
      showInGrades: [9, 10, 11, 12],
      filters: [],
    },
    {
      displayName: "Grade 9 On-Track by gender",
      rowGroup: "gender",
      columnGroup: "_currentQuarterOnTrackStatus",
      mode: "Bar Chart",
      showInGrades: [9],
      filters: [],
    },
    {
      displayName: "Grade 9 On-Track by Q" + currentQuarter + " by Team",
      rowGroup: "schoolTeam",
      columnGroup: "_currentQuarterOnTrackStatus",
      mode: "Stacked Bar Chart",
      showInGrades: [9],
      filters: [],
    },
    {
      displayName: "Grade 9 On-Track by Q" + currentQuarter + " by OTC",
      rowGroup: "OTCName",
      columnGroup: "_currentQuarterOnTrackStatus",
      mode: "Stacked Bar Chart",
      showInGrades: [9],
      filters: [],
    },
    {
      displayName: "Grade 9 On-Track by Race/Ethnicity",
      rowGroup: "raceAndEthnicity.race",
      columnGroup: "_currentQuarterOnTrackStatus",
      mode: "Stacked Bar Chart",
      showInGrades: [9],
      filters: [],
    },
    {
      displayName: "Grade 9 On-Track by SPED status",
      rowGroup: "specialEducation",
      columnGroup: "_currentQuarterOnTrackStatus",
      mode: "Stacked Bar Chart",
      showInGrades: [9],
      filters: [],
    },
    {
      displayName: "Grade 9 On-Track by ELL status",
      rowGroup: "englishLearner",
      columnGroup: "_currentQuarterOnTrackStatus",
      mode: "Stacked Bar Chart",
      showInGrades: [9],
      filters: [],
    },
    {
      displayName: "Grade 9 R/O Status",
      rowGroup: "currentGradeLevel",
      columnGroup: "g9RiskAndOpportunity.riskAndOpportunity",
      mode: "Stacked Bar Chart",
      showInGrades: [9],
      filters: [],
    },
    {
      displayName: "Grade 9 R/O to Q" + currentQuarter + " On-Track",
      rowGroup: "g9RiskAndOpportunity.riskAndOpportunity",
      columnGroup: "_currentQuarterOnTrackStatus",
      mode: "Stacked Bar Chart",
      showInGrades: [9],
      filters: [],
    },
    {
      displayName: "Grade 9 On-Track by Suspensions",
      rowGroup: "suspensions",
      columnGroup: "_currentQuarterOnTrackStatus",
      mode: "Stacked Bar Chart",
      showInGrades: [9],
      filters: [],
    },
    {
      displayName: "# of Applications by Plan",
      rowGroup: "plans",
      columnGroup: "_collegeAppsSubmitted",
      mode: "Stacked Bar Chart",
      showInGrades: [12],
      formatOption: "Show count",
      filters: [],
    },    {
      displayName: "SpEd # of Applications by Plan",
      rowGroup: "plans",
      columnGroup: "_collegeAppsSubmitted",
      mode: "Stacked Bar Chart",
      showInGrades: [12],
      formatOption: "Show count",
      filters: [
        {
          displayName: "Special Ed",
          displayType: ":",
          field: "specialEducation",
          type: "customIn",
          value: [true]
        }
      ],
    },    {
      displayName: "First Generation # of Applications by Plan",
      rowGroup: "plans",
      columnGroup: "_collegeAppsSubmitted",
      mode: "Stacked Bar Chart",
      showInGrades: [12],
      formatOption: "Show count",
      filters: [
        {
          displayName: "First Generation",
          displayType: ":",
          field: "firstGeneration",
          type: "customIn",
          value: [true]
        }
      ],
    },    {
      displayName: "English Learner # of Applications by Plan",
      rowGroup: "plans",
      columnGroup: "_collegeAppsSubmitted",
      mode: "Stacked Bar Chart",
      showInGrades: [12],
      formatOption: "Show count",
      filters: [
        {
          displayName: "English Learner",
          displayType: ":",
          field: "englishLearner",
          type: "customIn",
          value: [true]
        }
      ],
    },    {
      displayName: "FAFSA EdSight by Plan",
      rowGroup: "plans",
      columnGroup: "FAFSAStateStatus",
      mode: "Stacked Bar Chart",
      showInGrades: [12],
      formatOption: "Show count",
      filters: [],
    },    {
      displayName: "Sped FAFSA EdSight by Plan",
      rowGroup: "plans",
      columnGroup: "FAFSAStateStatus",
      mode: "Stacked Bar Chart",
      showInGrades: [12],
      formatOption: "Show count",
      filters: [
        {
          displayName: "Special Ed",
          displayType: ":",
          field: "specialEducation",
          type: "customIn",
          value: [true]
        }
      ],
    },    {
      displayName: "First Generation FAFSA EdSight by Plan",
      rowGroup: "plans",
      columnGroup: "FAFSAStateStatus",
      mode: "Stacked Bar Chart",
      showInGrades: [12],
      formatOption: "Show count",
      filters: [
        {
          displayName: "First Generation",
          displayType: ":",
          field: "firstGeneration",
          type: "customIn",
          value: [true]
        }
      ],
    },    {
      displayName: "English Learner FAFSA EdSight by Plan",
      rowGroup: "plans",
      columnGroup: "FAFSAStateStatus",
      mode: "Stacked Bar Chart",
      showInGrades: [12],
      formatOption: "Show count",
      filters: [
        {
          displayName: "English Learner",
          displayType: ":",
          field: "englishLearner",
          type: "customIn",
          value: [true]
        }
      ],
    },    {
      displayName: "FAFSA Intention by Plan",
      rowGroup: "plans",
      columnGroup: "FAFSAIntention",
      mode: "Stacked Bar Chart",
      showInGrades: [11],
      formatOption: "Show count",
      filters: [],
    },    {
      displayName: "SpEd FAFSA Intention by Plan",
      rowGroup: "plans",
      columnGroup: "FAFSAIntention",
      mode: "Stacked Bar Chart",
      showInGrades: [11],
      formatOption: "Show count",
      filters: [
        {
          displayName: "Special Ed",
          displayType: ":",
          field: "specialEducation",
          type: "customIn",
          value: [true]
        }
      ],
    },    {
      displayName: "First Generation FAFSA Intention by Plan",
      rowGroup: "plans",
      columnGroup: "FAFSAIntention",
      mode: "Stacked Bar Chart",
      showInGrades: [11],
      formatOption: "Show count",
      filters: [
        {
          displayName: "First Generation",
          displayType: ":",
          field: "firstGeneration",
          type: "customIn",
          value: [true]
        }
      ],
    },    {
      displayName: "English Learner FAFSA Intention by Plan",
      rowGroup: "plans",
      columnGroup: "FAFSAIntention",
      mode: "Stacked Bar Chart",
      showInGrades: [11],
      formatOption: "Show count",
      filters: [
        {
          displayName: "English Learner",
          displayType: ":",
          field: "englishLearner",
          type: "customIn",
          value: [true]
        }
      ],
    },
  ];
};
